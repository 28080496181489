<template>
    <form class="edit-main-site" @submit="submitUpdateMainSiteForm">
        
         <div class="card shadow">
            <div class="card-body">
                <div class="col-md-12">
                    <validation-error-message :errors="errors"></validation-error-message>
                    <div class="row">
                        <label class="col-sm-2 col-form-label">Site Name</label>
                        <div class="col-sm-10"> 
                            <label class="col-form-label font-weight-bold">{{ defaultSite.siteName }}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-xs-12 col-sm-2 col-form-label">Vendor Name</label>
                        <div class="col-sm-4">
                            <label class="col-form-label font-weight-bold">{{ defaultSite.vendorName }}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-xs-12 col-sm-2 col-form-label">Is Activated?</label>
                        <div class="col-sm-4">
                            <label class="col-form-label font-weight-bold">{{ defaultSite.activatedStatus }}</label>
                        </div>
                        <label class="col-xs-12 col-sm-2 col-form-label">Activated At</label>
                        <div class="col-sm-4">
                            <label class="col-form-label font-weight-bold">{{ defaultSite.activatedDate }}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-sm-2 col-form-label">Start Date</label>
                        <div class="col-sm-4">
                            <Datepicker
                                name="startDate"
                                v-model="startDate.value.value" 
                                :enableTimePicker="false" 
                                autoApply 
                                noToday 
                                :format="'dd/MM/yyyy'"
                                :minDate="minDate"
                                @update:modelValue="handleStartDate"
                            />
                            <span class="error-message validation">{{ errors.startDate }}</span>
                        </div>
                        <label class="col-sm-2 col-form-label">End Date</label>
                        <div class="col-sm-4">
                            <Datepicker 
                                name="endDate"
                                v-model="endDate.value.value" 
                                :enableTimePicker="false" 
                                autoApply 
                                noToday
                                :format="'dd/MM/yyyy'"
                                :minDate="minDate"
                                    />
                            <span class="error-message validation">{{ errors.endDate }}</span>
                        </div>
                    </div>
                    <div class="row" v-if="defaultSite.isActivated == true">
                        <label class="col-sm-2 col-form-label">Status</label>
                        <div class="col-sm-4">
                            <div class="form-radio d-inline-flex">
                                <input 
                                    type="radio" 
                                    class="radio"
                                    name="isActive"
                                    v-bind:value="true"
                                    v-model="isActive.value.value"
                                />
                                <label class="radio-label"> Active </label>
                            </div>
                            <div class="form-radio d-inline-flex">
                                <input 
                                    type="radio" 
                                    class="radio"
                                    name="isActive"
                                    v-bind:value="false"
                                    v-model="isActive.value.value" 
                                />
                                <label class="radio-label"> Inactive </label>
                            </div>
                            
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-xs-12 col-sm-2 col-form-label">Created By</label>
                        <div class="col-sm-4">
                            <label class="col-form-label font-weight-bold">{{ defaultSite.createdBy }}</label>
                        </div>
                        <label class="col-xs-12 col-sm-2 col-form-label">Created At</label>
                        <div class="col-sm-4">
                            <label class="col-form-label font-weight-bold">{{ defaultSite.createdAt }}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-xs-12 col-sm-2 col-form-label">Last Updated By</label>
                        <div class="col-sm-4">
                            <label class="col-form-label font-weight-bold">{{ defaultSite.updatedBy }}</label>
                        </div>
                        <label class="col-xs-12 col-sm-2 col-form-label">Updated At</label>
                        <div class="col-sm-4">
                            <label class="col-form-label font-weight-bold">{{ defaultSite.updatedAt }}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <h6 class="pb-3"><strong>API AUTHENTICATION SERVICE</strong></h6>
                        </div>
                        <div class="col-md-6 col-xs-12">
                            <div class="row mb-3">
                                <div class="col-md-4">
                                    <label>Username: <span class="text-danger text-bold">*</span></label>
                                </div>
                                <div class="col-md-8">
                                    <div class="position-relative">
                                        <Field :id="`username`" :name="`username`" class="form-control" type="text" v-model="formInfo.username" />
                                        <ErrorMessage class="error-message" :name="`username`" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-4">
                                    <label>Password: <span class="text-danger text-bold">*</span></label>
                                </div>
                                <div class="col-md-8">
                                    <div class="position-relative">
                                        <Field :id="`password`" :name="`password`" class="form-control" type="password" />
                                        <ErrorMessage class="error-message" :name="`password`" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-4">
                                    <label>Dashboard Url: <span class="text-danger text-bold">*</span></label>
                                </div>
                                <div class="col-md-8">
                                    <div class="position-relative">
                                        <Field :id="`dashboardUrl`" :name="`dashboardUrl`" class="form-control" type="text" v-model="formInfo.dashboardUrl" />
                                        <ErrorMessage class="error-message" :name="`dashboardUrl`" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-4">
                                    <label>Server IP: <span class="text-danger text-bold">*</span></label>
                                </div>
                                <div class="col-md-8">
                                    <div class="position-relative">
                                        <Field :id="`serverIp`" :name="`serverIp`" class="form-control" type="text" v-model="formInfo.serverIp" />
                                        <ErrorMessage class="error-message" :name="`serverIp`" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center pt-3">
                    <button class="btn btn-primary" type="submit" :disabled="submittingForm">
                        <span class="spinner-border spinner-border-sm" v-show="submittingForm"></span>
                        <span v-show="! submittingForm">SUBMIT</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { ref, reactive, inject, toRefs } from "vue";
import { useField, Field, useForm, ErrorMessage } from "vee-validate";
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import * as yup from "yup";
import moment from 'moment';
import { DateTimeClass } from '@/classes/datetime-class';
import formSiteActivation from "@/composables/vms/forms/form-site-activation";
import ValidationErrorMessage from "@/components/ValidationErrorMessage";
import displayToastNotification from "@/composables/toast-notification";

export default {
    name: "EditMainSite",
    components: { ValidationErrorMessage, ErrorMessage, Field },
    setup(props, { emit }) {

        const route = useRoute();

        const store = useStore();

        const defaultSite = inject("defaultSite");

        const toastNotification = displayToastNotification();

        const siteActivation = formSiteActivation();

        const submittingForm = ref(false);

        const defaultTapwaySettings = inject("defaultTapwaySettings");

        const schema = yup.object({ 
            startDate: yup.date().nullable().required("This field is required."),
            endDate: yup.date().nullable().required("This field is required.").when("startDate", (sd, schema) => moment(sd).isValid() ? schema.min(moment(sd), "End date should be after the start date.") : schema),
            username: yup.string().required('This field is required.'),
            password: yup.string().required('This field is required.'),
            dashboardUrl: yup.string().required('This field is required.'),
            serverIp: yup.string().required('This field is required.'),
        });

        const { handleSubmit, setErrors, errors } = useForm({ validationSchema: schema });

        let isActive = useField('isActive');

        let startDate = useField('startDate');

        let endDate = useField('endDate');

        const formInfo = reactive({
            minDate: moment().toString(),
            username: "",
            dashboardUrl: "",
            serverIp: ""
        })

        const submitUpdateMainSiteForm = handleSubmit(values => {
            submittingForm.value = true;

            const sdt = values.startDate ? moment(values.startDate) : "";
            const edt = values.endDate ? moment(values.endDate) : "";
           
            const putBody = {
                startDate: moment(sdt).format("YYYY-MM-DD"),
                endDate: moment(edt).format("YYYY-MM-DD"),
                isActive: Boolean(isActive.value.value),
                username: values.username,
                password: values.password,
                dashboardUrl: values.dashboardUrl,
                serverIp: values.serverIp,
            };
            
            store.dispatch("lprSite/updateModule", { id: route.params.id, body: putBody}).then(
                (response) => {
                    defaultSite.updatedAt = DateTimeClass.convertUTCToTimezone(response.data.data.updatedAt) 
                    defaultSite.updatedBy = response.data.data.updatedByAccName,
                    toastNotification.updateSuccessNotification();
                },
                (error) => {
                    if(error.response && error.response.data) {
                        if(error.response.status === 422) {
                            setErrors(error.response.data.error.validation)
                        } else {
                            toastNotification.errorNotification(error.response.data.error.message);
                        }
                    }
                }
            ).then(() => { submittingForm.value = false; });
        }); 

        const handleStartDate = (dt) => {
            const thisDate = siteActivation.onUpdateStartDate(dt);
            if(thisDate) {
                endDate.value.value = thisDate;
            }
        }

        const setupForm = () => { 
            isActive.value.value = defaultSite.isActive;
            startDate.value.value = defaultSite.startDate;
            endDate.value.value = defaultSite.endDate

            let modelObj = defaultTapwaySettings.value;
            
            formInfo.username = modelObj.username
            formInfo.dashboardUrl = modelObj.dashboardUrl
            formInfo.serverIp = modelObj.serverIp
            formInfo.activationId = modelObj.activationId
        }

        setupForm();
        
        return {
            defaultSite,
            isActive,
            startDate,
            endDate,
            errors,
            handleStartDate,
            submitUpdateMainSiteForm,
            submittingForm,
            ...toRefs(formInfo),
            formInfo,
        };
    }
}
</script>